
import { defineComponent, onMounted, ref } from "vue";
import { Tooltip } from "bootstrap";

export default defineComponent({
    props: {
        message: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const icon = ref(null);

        onMounted(() => {
            new Tooltip(icon.value as unknown as HTMLElement, {
                title: props.message,
                placement: "right"
            });
        });

        return {
            icon
        };
    }
});

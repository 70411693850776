
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        previewSource: {
            type: String,
            required: true
        }
    }
});

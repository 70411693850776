import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cdb35a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  type: "button",
  ref: "icon",
  class: "tooltip-button ps-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createVNode(_component_font_awesome_icon, {
      ref: "icon",
      icon: "info-circle"
    }, null, 512)
  ], 512))
}